
































































































































.hideDisconnected {
  display: none !important;
}

iframe {
  width: 100%;
  height: 100%;
}
.notification-count {
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: 120px;
      right: 10px;
      background-color: #073C5A;
      color: white;
      border-radius: 50%;
      padding: 0.5rem;
      font-size: 0.8rem;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
  }
  ::v-deep .p-toast .p-toast-message.p-toast-message-warn,
  ::v-deep .p-toast .p-toast-message.p-toast-message-success,
  ::v-deep .p-toast .p-toast-message.p-toast-message-error {
      background-color: #FFF;
      color: #000;
      .p-toast-message-content .p-toast-message-text .p-toast-detail {
          font-size: 0.7rem;
      }
  }
