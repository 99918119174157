















































































































































































































































































































































































































































































































































::v-deep .p-highlight .specialDay {
  background-color: inherit;
  color: inherit;
}
// This is used to override the special day css class from the calendar component
::v-deep .specialDay {
  height: inherit !important;
  width: inherit !important;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: var(--active-day-background-color);
  background-color: rgba(var(--active-day-background-color-r), var(--active-day-background-color-g), var(--active-day-background-color-b), var(--active-day-background-color-a));
}

