








































































































































































.fs-file-selector {
  position: relative;

  .fs-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fs-droppable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    input[type="file"] {
      visibility: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
    }
  }

  &.fs-drag-enter {
    // to prevent dragleave event triggered while user dragging over child items
    .fs-droppable::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 9999999;
    }
  }
}
