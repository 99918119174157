




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































#loadMore {
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  p {
    text-align: center;
    background-color: #f9f9f9;
     border-top: 1px solid #ddd;
     display: block;
     padding: 10px 0;
     border-radius: 5px;
  }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.identication-panel {
  position: fixed;
  bottom: 100px;
  right: 10px;
}

