/* Helpers */
/* vh height */
.vh-33 {
  height: 33.333vh !important;
}
.vh-66 {
  height: 66.666vh !important;
}
@for $i from 1 through 20 {
  .vh-#{$i*5} {
    height: $i*5vh !important;
  }
}
/* vh min-height */
.min-vh-33 {
  min-height: 33.333vh !important;
}
.min-vh-66 {
  min-height: 66.666vh !important;
}
@for $i from 1 through 20 {
  .min-vh-#{$i*5} {
    min-height: $i*5vh !important;
  }
}
/* vh max-height */
.max-vh-33 {
  max-height: 33.333vh !important;
}
.max-vh-66 {
  max-height: 66.666vh !important;
}
@for $i from 1 through 20 {
  .max-vh-#{$i*5} {
    max-height: $i*5vh !important;
  }
}
/* % height */
.h-33 {
  height: 33.333% !important;
}
.h-66 {
  height: 66.666% !important;
}
@for $i from 1 through 20 {
  .h-#{$i*5} {
    height: $i*5% !important;
  }
}
/* % min-height */
.min-h-33 {
  min-height: 33.333% !important;
}
.min-h-66 {
  min-height: 66.666% !important;
}
@for $i from 1 through 20 {
  .min-h-#{$i*5} {
    min-height: $i*5% !important;
  }
}
/* % max-height */
.max-h-33 {
  max-height: 33.333% !important;
}
.max-h-66 {
  max-height: 66.666% !important;
}
@for $i from 1 through 20 {
  .max-h-#{$i*5} {
    max-height: $i*5% !important;
  }
}
/* % max-width */
.max-w-33 {
  max-width: 33.333% !important;
}
.max-w-66 {
  max-width: 66.666% !important;
}
@for $i from 1 through 20 {
  .max-w-#{$i*5} {
    max-width: $i*5% !important;
  }
}
/* % width */
.w-33 {
  width: 33.333% !important;
}
.w-66 {
  width: 66.666% !important;
}
@for $i from 1 through 20 {
  .w-#{$i*5} {
    width: $i*5% !important;
  }
}
/* px with */
@for $i from 1 through 50 {
  .w-px-#{$i} {
    width: $i + px !important;
  }
}
/* px width sm */
@include media-breakpoint-down(sm) {
  @for $i from 1 through 50 {
    .w-px-sm-#{$i} {
      width: $i + px !important;
    }
  }
}
@for $i from 11 through 200 {
  .w-px-#{$i*5} {
    width: $i*5px !important;
  }
}
/* px width sm */
@include media-breakpoint-down(sm) {
  @for $i from 11 through 200 {
    .w-px-sm-#{$i*5} {
      width: $i*5px !important;
    }
  }
}

/* px height */
@for $i from 1 through 100 {
  .h-px-#{$i} {
    height: $i + px !important;
  }
}
@for $i from 21 through 200 {
  .h-px-#{$i*5} {
    height: $i*5px !important;
  }
}
/* px min-height */
@for $i from 1 through 200 {
  .min-h-px-#{$i*5} {
    min-height: $i*5px !important;
  }
}
/* px max-height */
@for $i from 1 through 200 {
  .max-h-px-#{$i*5} {
    max-height: $i*5px !important;
  }
}
/* px min-width */
@for $i from 1 through 200 {
  .min-w-px-#{$i*5} {
    min-width: $i*5px !important;
  }
}
/* px max-width */
@for $i from 1 through 200 {
  .max-w-px-#{$i*5} {
    max-width: $i*5px !important;
  }
}
/* rem line-height */
@for $i from 1 through 40 {
  .line-height-rem-#{$i} {
    line-height: $i*0.1rem !important;
  }
}
/* px line-height */
@for $i from 1 through 40 {
  .line-height-px-#{$i} {
    line-height: $i*1px !important;
  }
}
/* px font-size */
@for $i from 1 through 80 {
  .font-size-px-#{$i} {
    font-size: $i*1px !important;
  }
}
/* rem font-size */
@for $i from 1 through 40 {
  .font-size-rem-#{$i} {
    font-size: $i*0.1rem !important;
  }
}
/* rem font-size md */
@include media-breakpoint-up(md) {
  @for $i from 1 through 40 {
    .font-size-rem-md-#{$i} {
      font-size: $i*0.1rem !important;
    }
  }
}
/* cursors */
.cursor-initial {
  cursor: initial !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-crosshair {
  cursor: crosshair !important;
}
.pointer-none {
  pointer-events: none !important;
}
/* text-decoration */
.text-decoration {
  text-decoration: underline;
}
.text-decoration-dashed {
  text-decoration: underline;
  text-decoration-style: dashed;
}
.text-decoration-dotted {
  text-decoration: underline;
  text-decoration-style: dotted;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
/* positions */
@for $i from 0 through 50 {
  .position-left-#{$i} {
    left: $i*5px;
  }
  .position-right-#{$i} {
    right: $i*5px;
  }
  .position-top-#{$i} {
    top: $i*5px;
  }
  .position-bottom-#{$i} {
    bottom: $i*5px;
  }
}
@for $i from 1 through 100 {
  .position-left-px-#{$i} {
    left: $i + px;
  }
  .position-right-px-#{$i} {
    right: $i + px;
  }
  .position-top-px-#{$i} {
    top: $i + px;
  }
  .position-bottom-px-#{$i} {
    bottom: $i + px;
  }
}
/* margins */
@for $i from 0 through 50 {
  .margin-left-#{$i} {
    margin-left: $i + px !important;
  }
  .margin-right-#{$i} {
    margin-right: $i + px !important;
  }
  .margin-top-#{$i} {
    margin-top: $i + px !important;
  }
  .margin-bottom-#{$i} {
    margin-bottom: $i + px !important;
  }
}
@for $i from 11 through 50 {
  .margin-left-#{$i*5} {
    margin-left: $i*5px !important;
  }
  .margin-right-#{$i*5} {
    margin-right: $i*5px !important;
  }
  .margin-top-#{$i*5} {
    margin-top: $i*5px !important;
  }
  .margin-bottom-#{$i*5} {
    margin-bottom: $i*5px !important;
  }
}
/* paddings */
@for $i from 0 through 50 {
  .padding-left-#{$i} {
    padding-left: $i + px !important;
  }
  .padding-right-#{$i} {
    padding-right: $i + px !important;
  }
  .padding-top-#{$i} {
    padding-top: $i + px !important;
  }
  .padding-bottom-#{$i} {
    padding-bottom: $i + px !important;
  }
}
@for $i from 11 through 50 {
  .padding-left-#{$i*5} {
    padding-left: $i*5px !important;
  }
  .padding-right-#{$i*5} {
    padding-right: $i*5px !important;
  }
  .padding-top-#{$i*5} {
    padding-top: $i*5px !important;
  }
  .padding-bottom-#{$i*5} {
    padding-bottom: $i*5px !important;
  }
}
/* min margins */
@for $i from 0 through 50 {
  .margin-min-left-#{$i} {
    margin-left: -$i + px !important;
  }
  .margin-min-right-#{$i} {
    margin-right: -$i + px !important;
  }
  .margin-min-top-#{$i} {
    margin-top: -$i + px !important;
  }
  .margin-min-bottom-#{$i} {
    margin-bottom: -$i + px !important;
  }
}
@for $i from 11 through 50 {
  .margin-min-left-#{$i * 5} {
    margin-left: $i*-5px !important;
  }
  .margin-min-right-#{$i * 5} {
    margin-right: $i*-5px !important;
  }
  .margin-min-top-#{$i * 5} {
    margin-top: $i*-5px !important;
  }
  .margin-min-bottom-#{$i * 5} {
    margin-bottom: $i*-5px !important;
  }
}
/* rounded border */
.rounded-left {
  border-radius: 10px 0 0 10px;
}
.rounded-right {
  border-radius: 0 10px 10px 0;
}
.rounded-top {
  border-radius: 10px 10px 0 0;
}
.rounded-bottom {
  border-radius: 0 0 10px 10px;
}
.rounded-left-big {
  border-top-left-radius: 20px 50%;
  border-bottom-left-radius: 20px 50%;
}
.rounded-right-big {
  border-top-right-radius: 20px 50%;
  border-bottom-right-radius: 20px 50%;
}
.rounded-top-big {
  border-radius: 50px 50px 0 0;
}
.rounded-bottom-big {
  border-radius: 0 0 50px 50px;
}
.rounded-none {
  border-radius: 0;
}

/* borders */
.border-dashed {
  border: 1px dashed;
}
.border-bottom-dashed {
  border-bottom: 1px dashed;
}
/* shadow */
.shadow-blur {
  -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
  -moz-box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);
}
.text-shadow {
  text-shadow: 0 0 10px rgba(0,0,0,0.9)
}
/* opacity */
@for $i from 0 through 10 {
  .opacity-#{$i} {
    opacity: $i*0.1 !important;
  }
}
/* svg */
.svg path {
  fill: var(--primary) !important;
}
.svg-white path {
  fill: var(--white) !important;
}
.svg-gray path {
  fill: var(--gray) !important;
}
/* z-index */
@for $i from 1 through 20 {
  .z-index-#{$i} {
    z-index: $i*100 !important;
  }
}
/* Animations */
.blink-animation {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  50% { opacity: 0; }
}
/* White space / word break */
.white-space-pre-wrap {
  white-space: pre-wrap;
}
.word-break-break-all {
  word-break: break-all;
}
/* no select */
.disable-select * {
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-select: none; // Safari
  -moz-user-select: none; // Old versions of Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none; // Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox
}
/* manual font-weight options */
.fw-manual-600 {
  font-weight: 600;
}
